const WarrantyFooter = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.34517 13.3057C8.18812 13.3071 9.02401 13.1518 9.80664 12.8479L9.80811 12.8473L9.82997 12.8388C10.1354 12.7194 10.4821 12.7863 10.7211 13.0106C10.7216 13.0111 10.7221 13.0116 10.7226 13.012L15.6104 17.68C15.6105 17.6801 15.6106 17.6803 15.6107 17.6804C15.9959 18.0485 16.5113 18.2498 17.0421 18.2498C17.573 18.2498 18.0886 18.0484 18.4737 17.68C18.8601 17.3106 19.0837 16.8021 19.0837 16.2645C19.0837 15.7269 18.8601 15.2185 18.4737 14.849L18.4738 14.849L18.4684 14.844L14.396 11.0249L13.2137 9.83703C13.2136 9.83687 13.2134 9.83671 13.2133 9.83655C13.1988 9.82147 13.1946 9.79923 13.2026 9.77986L12.5095 9.4932L13.2026 9.77986L13.3728 9.36826L13.3728 9.36827L13.3751 9.36276C13.7541 8.42541 13.8906 7.41268 13.7717 6.41199C13.6528 5.41138 13.2824 4.45563 12.695 3.62627C12.1078 2.7971 11.3216 2.11958 10.4061 1.64894C9.49079 1.17836 8.47185 0.927758 7.43618 0.916862L7.4361 0.916861C6.83107 0.910562 6.37115 1.28759 6.20731 1.78238C6.04995 2.2576 6.17256 2.80597 6.57026 3.18797L8.10227 4.65946L8.10221 4.65952L8.11016 4.66694C8.23828 4.78647 8.34056 4.92596 8.41331 5.07758C8.47058 5.21964 8.48407 5.37262 8.45342 5.52009C8.42202 5.67114 8.34453 5.81475 8.22518 5.93169L6.15432 7.91229C5.9817 8.07455 5.74317 8.17133 5.48795 8.17277C5.23719 8.17108 5.00325 8.07538 4.83461 7.91562L3.38621 6.50763C2.95105 6.08462 2.34315 5.96037 1.82351 6.14763C1.27529 6.34519 0.88231 6.87579 0.935131 7.55207C0.984467 8.18373 1.13423 8.80724 1.38185 9.40036C1.85271 10.5282 2.65568 11.4946 3.68686 12.1843C4.75685 12.9168 6.03631 13.3075 7.34517 13.3057ZM7.34517 13.3057C7.34475 13.3057 7.34433 13.3057 7.34391 13.3057L7.34526 12.5557L7.34644 13.3057C7.34601 13.3057 7.34559 13.3057 7.34517 13.3057Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default WarrantyFooter
